import React, { useEffect } from "react";
import { Center, Spinner } from "@chakra-ui/react";

export const Redirect = ({ href }) => {
  useEffect(() => {
    window.location.href = href;
  }, [href]);

  return (
    <Center h="100vh">
      <Spinner />
    </Center>
  );
};
