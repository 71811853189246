import * as yup from "yup";
import { messages } from "consts";

yup.addMethod(yup.string, "isValidName", function (errorMessage) {
  return this.test("is-valid-name", errorMessage, function (value) {
    const { path, createError } = this;
    try {
      const values = value.trim().split(" ");
      if (values.length < 2) throw new Error();
      const letters = /^[A-Za-zÀ-ú]+$/;
      for (const value of values) {
        if (!value.match(letters)) throw new Error();
      }
      return true;
    } catch (error) {
      return createError({ path, message: messages.error.invalidName });
    }
  });
});

export default yup;
