import React, { useState } from "react";
import { Box, Center, HStack, Heading, Icon, StackDivider, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { IoAddOutline, IoRemoveOutline } from "react-icons/io5";

const data = [
  {
    _id: Math.random().toString(),
    title: "O que é o ConsigSys?",
    content: `O ConsigSys é um sistema de vendas por consignação que permite aos revendedores gerenciar seus produtos de forma eficiente e aos representantes realizar vendas offline. Ele também inclui um programa de pontuação chamado "Rewards" para incentivar o desempenho dos revendedores.`,
  },
  {
    _id: Math.random().toString(),
    title: "Quais são os principais componentes do ConsigSys?",
    content:
      "O ConsigSys é composto por um painel de gerenciamento web (Dashboard), um aplicativo para representantes que funciona offline e um aplicativo para revendedores com o programa Rewards.",
  },
  {
    _id: Math.random().toString(),
    title: "Como faço para acessar o Dashboard do ConsigSys?",
    content:
      "Você pode acessar o Dashboard do ConsigSys através de um navegador da web. Basta digitar o endereço URL fornecido pela empresa e fazer login com suas credenciais.",
  },
  {
    _id: Math.random().toString(),
    title: "O aplicativo do representante funciona offline?",
    content:
      "Sim, o aplicativo do representante do ConsigSys é projetado para funcionar completamente offline. Os representantes podem realizar vendas mesmo quando não têm acesso à internet, e os dados são sincronizados quando a conexão é restabelecida.",
  },
  {
    _id: Math.random().toString(),
    title: "Como os representantes sincronizam seus dados offline com o sistema?",
    content:
      "Os representantes podem sincronizar os dados offline com o sistema quando têm uma conexão com a internet disponível. Isso pode ser feito através do aplicativo do representante, que possui uma função de sincronização.",
  },
  {
    _id: Math.random().toString(),
    title: "O que é o programa Rewards no aplicativo do revendedor?",
    content:
      "O programa Rewards é um sistema de pontuação no aplicativo do revendedor do ConsigSys. Ele recompensa os revendedores com pontos com base em seu desempenho nas vendas e em outras métricas definidas pela empresa.",
  },
  {
    _id: Math.random().toString(),
    title: "Como os revendedores podem ganhar pontos no programa Rewards?",
    content:
      "Os revendedores podem ganhar pontos no programa Rewards através de vendas bem-sucedidas, cumprimento de metas, participação em treinamentos e outras ações positivas definidas pela empresa.",
  },
  {
    _id: Math.random().toString(),
    title: "Como os revendedores podem usar os pontos do programa Rewards?",
    content:
      "Os pontos do programa Rewards podem ser trocados por recompensas, prêmios ou benefícios oferecidos pela empresa. Isso pode incluir descontos em produtos, viagens, produtos gratuitos, entre outros.",
  },
  {
    _id: Math.random().toString(),
    title: "9. Existe algum suporte técnico disponível para o ConsigSys?",
    content:
      "Sim, oferecemos suporte técnico para ajudar a solucionar quaisquer problemas ou dúvidas relacionados ao ConsigSys. Você pode entrar em contato com nossa equipe de suporte através dos canais de comunicação especificados.",
  },
];

const Duvidas = () => {
  const [expanded, setExpanded] = useState({});

  return (
    <Center id="duvidas" bg="#FCFCFC">
      <Box maxW="1300px" w="90%" py="100px">
        <Heading color="main.500" size="2xl" textAlign="center" mb="60px">
          Ficou alguma dúvida?
        </Heading>
        <VStack alignItems="stretch" divider={<StackDivider />}>
          {_.map(data, (item) => (
            <Box key={item._id} w="100%">
              <HStack
                py="20px"
                cursor="pointer"
                color={expanded[item._id] ? "main.500" : "gray.800"}
                onClick={() => setExpanded((state) => ({ ...state, [item._id]: !state[item._id] }))}
              >
                <Text fontSize="xl" fontWeight="600" flex="1">
                  {item.title}
                </Text>
                <Icon as={expanded[item._id] ? IoRemoveOutline : IoAddOutline} boxSize="30px" />
              </HStack>
              {expanded[item._id] && (
                <Text color="gray.600" my="10px">
                  {item.content}
                </Text>
              )}
            </Box>
          ))}
        </VStack>
      </Box>
    </Center>
  );
};

export default Duvidas;
