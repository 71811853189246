import React, { useEffect } from "react";
import Planos from "../Home/Planos";

export const Plans = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <Planos />;
};
