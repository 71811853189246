export const messages = {
  success: {
    saveData: "Registro salvo com sucesso!",
  },
  error: {
    saveData: "Problemas ao salvar registro.",
    required: "Este campo é obrigatório.",
    invalidEmail: "O e-mail informado é inválido.",
    invalidName: "O nome informado é inválido. Por favor informe o nome completo.",
  },
};
