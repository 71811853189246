import React from "react";
import { HashLink } from "react-router-hash-link";
import { Box, SimpleGrid, Image, Button, HStack, VStack, Text, Heading } from "@chakra-ui/react";
import _ from "lodash";

const QuemSomos = () => {
  return (
    <SimpleGrid id="dobre-seu-faturamento" columns={{ base: 1, lg: 2 }} bg="main.500">
      <HStack justifyContent="flex-end">
        <VStack p={{ base: "40px 20px", lg: "0 60px 0 0" }} w={{ base: "100%", lg: "650px" }} justifyContent="center" alignItems="stretch">
          <Heading size="2xl" color="white">
            Dobre seu faturamento
          </Heading>
          <Box h="20px" />
          <Text color="white" fontSize="lg" lineHeight="10">
            O ConsigSys é muito mais do que um sistema. É uma ferramenta moderna que vai otimizar o controle de estoque em consignação da
            sua empresa. Nosso software para o controle consignado é a solução ideal que vai levar a sua empresa para outro nível. O
            controle financeiro das vendas consignadas se torna bem mais prático e eficiente.
          </Text>
          <Box h="20px" />
          <HStack>
            <Button as={HashLink} to="/#planos" size="lg" w={{ base: "100%", lg: "auto" }} colorScheme="whiteAlpha">
              Quero começar
            </Button>
          </HStack>
        </VStack>
      </HStack>
      <Box w="100%">
        <Image w="100%" src={require("assets/imgs/img-01.jpg")} />
      </Box>
    </SimpleGrid>
  );
};

export default QuemSomos;
