import React from "react";
import { SimpleGrid, Link, Box, Text, Center, HStack, Icon, VStack, Grid, GridItem } from "@chakra-ui/react";
import { Logo } from "components";
import { FaEnvelope, FaInstagram, FaWhatsapp } from "react-icons/fa";

export const Footer = () => {
  return (
    <Center bg="gray.100">
      <Box maxW="1300px" w="100%" p="40px">
        <Grid templateColumns="repeat(12,1fr)" gap={4} py="60px">
          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <Logo width={200} color="gray.400" />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <VStack alignItems="flex-start">
              <Text color="gray.600">Avenida Tedinho Alvim, 90 - 303 - Liberdade, Divinópolis - MG, CEP 35502-634</Text>
              <Link href="mailto:contato@consigsys.com" _hover={{ textDecoration: "none", color: "main.500" }}>
                <HStack>
                  <Center w="26px" h="26px" borderRadius="full" bg="gray.500">
                    <Icon as={FaEnvelope} boxSize="12px" color="white" />
                  </Center>
                  <Text>contato@consigsys.com</Text>
                </HStack>
              </Link>
              <Link href="https://wa.me/5512988771212" target="_blank" _hover={{ textDecoration: "none", color: "main.500" }}>
                <HStack>
                  <Center w="26px" h="26px" borderRadius="full" bg="gray.500">
                    <Icon as={FaWhatsapp} color="white" />
                  </Center>
                  <Text>(12) 9 8877-1212</Text>
                </HStack>
              </Link>
              <Link href="https://www.instagram.com/consigsys" _hover={{ textDecoration: "none", color: "main.500" }}>
                <HStack>
                  <Center w="26px" h="26px" borderRadius="full" bg="gray.500">
                    <Icon as={FaInstagram} boxSize="12px" color="white" />
                  </Center>
                  <Text>@consigsys</Text>
                </HStack>
              </Link>
            </VStack>
          </GridItem>
        </Grid>
        <HStack justifyContent="center" mb="10px" spacing="20px">
          <Link textDecoration="underline" href="/politica-de-privacidade" target="_blank">
            Política de Privacidade
          </Link>
          <Link textDecoration="underline" href="/termos" target="_blank">
            Termos de Uso
          </Link>
        </HStack>
        <Center>
          <Text fontSize="sm">&copy; Todos os direitos reservados | ConsigSys {new Date().getFullYear()}.</Text>
        </Center>
      </Box>
    </Center>
  );
};
