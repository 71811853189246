import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { api, yup } from "lib";
import { messages } from "consts";
import { MdChevronRight, MdClose } from "react-icons/md";

const Congrats = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody as={VStack} alignItems="stretch" spacing={2} p="40px" pt="0">
          <Heading pb="10px">Parabéns</Heading>
          <Text>Obrigado por realizar seu pré-cadastro conosco.</Text>
          <Text>
            No momento, todos as nossas vagas de lançamento da versão atual do ConsigSys foram preenchidas. Mas não se preocupe! Assim que
            novas vagas forem disponibilizadas te avisaremos por e-mail.
          </Text>
          <Text>Em caso de dúvidas, nosso time está a sua disposição para atendimento através do WhatsApp.</Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const SignUp = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenCongrats, onOpen: onOpenCongrats, onClose: onCloseCongrats } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    setFormData({ status: "prospect", phone1Category: "cellphone" });
  }, [isOpen]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.post("/prospects", data);
        onClose();
        onOpenCongrats();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [toast, onClose, onOpenCongrats]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required).isValidName(),
        email: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
        phone1: yup.string().required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Box flex="1">
              <Text>Plano Standard</Text>
              <Text fontSize="md" fontWeight="normal">
                Antes de continuar precisamos que você nos forneça algumas informações.
              </Text>
            </Box>
            <IconButton variant="ghost" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <Divider />
          <ModalBody p="30px">
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={{ base: 12, lg: 12 }}>
                <FormControl isRequired={true} isInvalid={formErrors.name}>
                  <FormLabel fontSize="sm">Nome</FormLabel>
                  <Input
                    value={formData.name ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.name}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 12 }}>
                <FormControl isRequired={true} isInvalid={formErrors.email}>
                  <FormLabel fontSize="sm">E-mail</FormLabel>
                  <Input
                    value={formData.email ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 12 }}>
                <FormControl isRequired={true} isInvalid={formErrors.phone1}>
                  <FormLabel fontSize="sm">Celular</FormLabel>
                  <Input
                    as={InputMask}
                    mask="(99) 99999-9999"
                    value={formData.phone1 ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, phone1: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.phone1}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack}>
            <Button colorScheme="main" rightIcon={<Icon as={MdChevronRight} />} onClick={handleSubmit} isLoading={isLoadingSaveData}>
              continuar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Congrats isOpen={isOpenCongrats} onClose={onCloseCongrats} />
    </>
  );
};

export default SignUp;
