import React from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { MdCheck } from "react-icons/md";
import SignUp from "./SignUp";

const Duvidas = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Center id="planos" bg="#FCFCFC">
        <Box maxW="1300px" w="90%" py="100px">
          <Heading color="main.500" size="2xl" textAlign="center" mb="60px">
            Planos e preços
          </Heading>
          <Grid templateColumns="repeat(12,1fr)" gap={4}>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <VStack bg="gray.100" borderColor="main.500" borderWidth="2px" borderRadius="xl" p="30px" spacing="20px">
                <Box>
                  <Heading>Standard</Heading>
                  <Text fontSize="sm">Otimize suas vendas centralizando sua operação.</Text>
                  <HStack spacing="2px">
                    <Text>R$</Text>
                    <Text fontSize="4xl">199</Text>
                    <Text>/mês</Text>
                  </HStack>
                  <Text fontSize="sm" opacity="0.8">
                    + R$1,00 por acerto realizado com o revendedor
                  </Text>
                </Box>
                <Button colorScheme="main" w="100%" onClick={onOpen}>
                  Quero contratar
                </Button>
                <List>
                  <ListItem>
                    <ListIcon as={MdCheck} /> Painel de gerenciamento da empresa.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheck} /> Aplicativo do representante.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheck} /> Aplicativo da revendedora.
                  </ListItem>
                </List>
              </VStack>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <VStack bg="gray.100" borderRadius="xl" p="30px" spacing="20px">
                <Box>
                  <Heading>Premium</Heading>
                  <Text fontSize="sm">Automatize processos e acelere a sua evolução.</Text>
                  <HStack spacing="2px">
                    <Text>R$</Text>
                    <Text fontSize="4xl">399</Text>
                    <Text>/mês</Text>
                  </HStack>
                  <Text fontSize="sm" opacity="0.8">
                    + R$1,00 por acerto realizado com o revendedor
                  </Text>
                </Box>
                <Button colorScheme="main" w="100%" isDisabled={true}>
                  Em breve
                </Button>
                <List>
                  <ListItem>
                    <ListIcon as={MdCheck} /> Painel de gerenciamento da empresa.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheck} /> Aplicativo do representante.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheck} /> Aplicativo da revendedora.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheck} /> Emissão de NF-e, NFC-e e NFS-e.
                  </ListItem>
                </List>
              </VStack>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <VStack bg="gray.100" borderRadius="xl" p="30px" spacing="20px">
                <Box>
                  <Heading>Corporate</Heading>
                  <Text fontSize="sm">Máxima performance para a sua empresa..</Text>
                  <HStack spacing="2px">
                    <Text>R$</Text>
                    <Text fontSize="4xl">599</Text>
                    <Text>/mês</Text>
                  </HStack>
                  <Text fontSize="sm" opacity="0.8">
                    + R$1,00 por acerto realizado com o revendedor
                  </Text>
                </Box>
                <Button colorScheme="main" w="100%" isDisabled={true}>
                  Em breve
                </Button>
                <List>
                  <ListItem>
                    <ListIcon as={MdCheck} /> Painel de gerenciamento da empresa.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheck} /> Aplicativo do representante.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheck} /> Aplicativo da revendedora.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheck} /> Emissão de NF-e, NFC-e e NFS-e.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheck} /> Emissão de boleto, pagamentos via Cartão de Crédito e Pix.
                  </ListItem>
                </List>
              </VStack>
            </GridItem>
          </Grid>
        </Box>
      </Center>
      <SignUp isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Duvidas;
