import React from "react";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import * as Pages from "./pages";
import { Footer, Header, Redirect } from "components";
import { AppVersionError } from "exceptions";

const Container = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

const AppProvider = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<Container />}>
            <Route index={true} path="/" element={<Pages.Home />} />
            <Route path="/planos" element={<Pages.Plans />} />
          </Route>
          <Route path="/termos" element={<Redirect href="/termos.html" />} />
          <Route path="/politica-de-privacidade" element={<Redirect href="/politica-de-privacidade.html" />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </BrowserRouter>
      <AppVersionError />
    </>
  );
};

export default AppProvider;
