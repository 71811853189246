import React, { useState } from "react";
import { Box, Center, HStack, Heading, Text } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const items = [
  {
    _id: Math.random().toString(),
    text: "O ConsigSys transformou a forma como gerenciamos nosso estoque e vendemos produtos em nossa loja. A facilidade de uso do aplicativo do representante, mesmo offline, simplificou nossas vendas. Além disso, o programa Rewards motivou nossos revendedores, aumentando nossos resultados. Estamos muito satisfeitos com essa solução!",
    author: "Maria Silva, Dona de Loja de Roupas",
  },
  {
    _id: Math.random().toString(),
    text: "Trabalhar com o aplicativo do ConsigSys é incrível! Posso atender meus clientes mesmo em locais sem internet, e os dados são sincronizados automaticamente. Além disso, o programa Rewards me incentivou a superar minhas metas e ganhar ótimas recompensas. Estou muito contente com essa ferramenta.",
    author: "Pedro Santos, Representante de Vendas",
  },
  {
    _id: Math.random().toString(),
    text: "O ConsigSys simplificou minha vida como revendedora. A facilidade de uso do aplicativo e o programa Rewards me ajudaram a acompanhar minhas vendas e a ganhar prêmios incríveis. Recomendo fortemente a todos os revendedores que desejam otimizar seus negócios.",
    author: "Carla Rodrigues, Revendedora de Produtos Cosméticos",
  },
  {
    _id: Math.random().toString(),
    text: "Implementar o ConsigSys em nossa empresa foi uma decisão estratégica que trouxe resultados notáveis. O Dashboard de gerenciamento web nos permite monitorar o desempenho em tempo real, e a funcionalidade offline do aplicativo do representante é um grande diferencial. O programa Rewards também aumentou a motivação de nossa equipe de vendas. Excelente solução!",
    author: "João Ferreira, Gerente de Operações",
  },
];

const Depoimentos = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <Center id="depoimentos" bg="#FCFCFC">
      <Box maxW="1300px" w="90%" py="100px">
        <Heading color="main.500" size="2xl" textAlign="center" mb="40px">
          O que nossos clientes falam sobre nós
        </Heading>
        <Carousel
          selectedItem={activeIndex}
          onChange={(index) => setActiveIndex(index)}
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          emulateTouch={true}
        >
          {items.map((item) => (
            <div key={item._id}>
              <Text fontSize="lg" color="gray.600" mb="20px">
                {item.text}
              </Text>
              <Text fontSize="sm" color="gray.600">
                {item.author}
              </Text>
            </div>
          ))}
        </Carousel>
        <HStack justifyContent="center">
          {items.map((item, index) => (
            <Box
              key={item._id}
              w="10px"
              h="10px"
              borderRadius="full"
              bg="main.500"
              cursor="pointer"
              opacity={index === activeIndex ? "1" : "0.5"}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </HStack>
      </Box>
    </Center>
  );
};

export default Depoimentos;
