import React, { createContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  SlideFade,
  StackDivider,
  useDisclosure,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { Logo } from "components";
import { MdClose, MdMenu } from "react-icons/md";
import { FaChevronUp, FaWhatsapp } from "react-icons/fa";
import Menu from "./menu";

export const HeaderContext = createContext();

export const Header = () => {
  const location = useLocation();
  const [pageYOffset, setPageYOffset] = useState(0);
  const isMinimized = useMemo(() => pageYOffset > 100, [pageYOffset]);
  const [isMobileView] = useMediaQuery("(max-width: 940px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isWhatsAppIn, setIsWhatsAppIn] = useState(false);
  const pathname = useMemo(() => {
    const [__, pathname] = location.pathname.split("/");
    return pathname;
  }, [location.pathname]);

  useEffect(() => {
    const onScroll = () => setPageYOffset(window.pageYOffset);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setIsWhatsAppIn(true), 2000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <HeaderContext.Provider value={{ isMinimized, isMobileView }}>
      <Box h={isMinimized ? "60px" : "80px"} />
      <Center position="fixed" top="0" left="0" w="100%" bg="white" borderBottomWidth="1px" zIndex="99">
        <HStack
          w="1300px"
          h={isMinimized ? "60px" : "80px"}
          px="20px"
          transition="400ms"
          justifyContent="space-between"
          alignItems="center"
        >
          {isMobileView && <IconButton variant="outline" icon={<Icon as={MdMenu} />} isRound onClick={onOpen} />}
          <HStack>
            <Link href="/">
              <Logo width={isMinimized ? 50 : 70} />
            </Link>
            <HStack spacing={0}>
              <Heading size={isMinimized ? "md" : "lg"}>Consig</Heading>
              <Heading size={isMinimized ? "md" : "lg"} fontWeight="normal">
                Sys
              </Heading>
            </HStack>
          </HStack>
          <HStack>{!isMobileView && <Menu isMinimized={isMinimized} />}</HStack>
        </HStack>
      </Center>

      <Drawer isOpen={isOpen} placement="top" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <IconButton variant="outline" icon={<Icon as={MdClose} />} isRound onClick={onClose} />
          </DrawerHeader>
          <DrawerBody pb="20px">
            <VStack divider={<StackDivider />} alignItems="left">
              <Menu isMinimized={isMinimized} />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box position="fixed" bottom="20px" left="20px" zIndex="99">
        <SlideFade in={isMinimized} offsetY="20px">
          <Center
            as={Link}
            href="#home"
            bg="white"
            w="54px"
            h="54px"
            borderRadius="full"
            cursor="pointer"
            borderWidth="1px"
            transition="400ms"
            _hover={{ color: "main.500" }}
          >
            <Icon as={FaChevronUp} transition="400ms" />
          </Center>
        </SlideFade>
      </Box>

      <Box position="fixed" bottom="20px" right="20px" zIndex="99">
        <SlideFade in={isWhatsAppIn} offsetY="20px">
          <IconButton
            isRound
            colorScheme="green"
            w="80px"
            h="80px"
            icon={<Icon as={FaWhatsapp} boxSize="40px" />}
            onClick={() =>
              window.open(
                "https://wa.me/5512988771212?text=Olá! Gostaria de obter mais informações sobre o *ConsigSys*. Pode me ajudar por favor?"
              )
            }
          />
        </SlideFade>
      </Box>
    </HeaderContext.Provider>
  );
};
