import React from "react";
import { HashLink } from "react-router-hash-link";
import { Box, SimpleGrid, Image, Button, HStack, VStack, Text, Heading } from "@chakra-ui/react";
import _ from "lodash";

const QuemSomos = () => {
  return (
    <SimpleGrid id="quem-somos" columns={{ base: 1, lg: 2 }} bg="main.500">
      <HStack justifyContent="flex-end">
        <VStack p={{ base: "40px 20px", lg: "0 60px 0 0" }} w={{ base: "100%", lg: "650px" }} justifyContent="center" alignItems="stretch">
          <Heading size="2xl" color="white">
            Quem somos
          </Heading>
          <Box h="20px" />
          <Text color="white" fontSize="lg" lineHeight="10">
            O ConsigSys é a solução definitiva para gerenciar consignações de forma eficiente. Com integração direta em aplicativos para
            representantes comerciais e sacoleiras, este sistema inovador resolve os desafios comuns enfrentados pelas empresas de
            consignação.
          </Text>
          <Text color="white" fontSize="lg" lineHeight="10">
            Oferecemos gerenciamento preciso e simplificado das operações, proporcionando maior controle, acesso em tempo real aos dados e
            um acompanhamento aprimorado das vendas e estoque. É a ferramenta moderna que sua empresa precisa para melhorar a gestão de
            consignações.
          </Text>
          <Box h="20px" />
          <HStack>
            <Button as={HashLink} to="/#planos" size="lg" w={{ base: "100%", lg: "auto" }} colorScheme="whiteAlpha">
              Quero começar
            </Button>
          </HStack>
        </VStack>
      </HStack>
      <Box w="100%">
        <Image w="100%" src={require("assets/imgs/img-02.jpg")} />
      </Box>
    </SimpleGrid>
  );
};

export default QuemSomos;
