import React, { useEffect } from "react";
import Faturamento from "./Faturamento";
import QuemSomos from "./QuemSomos";
import Depoimentos from "./Depoimentos";
import Planos from "./Planos";
import Duvidas from "./Duvidas";

export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Faturamento />
      <Depoimentos />
      <QuemSomos />
      <Planos />
      <Duvidas />
    </>
  );
};
